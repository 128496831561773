import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Profile = () => {
  return (
    <>
    <Header/>
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography sx={{ fontWeight: "bold", color: "gray" }}>
        My Account
        </Typography>
        <br />
        <TextField
          type="email"
          placeholder="Enter your email"
          fullWidth
          sx={{ mt: 2 }}
        />
        <br />
        <TextField
          type="email"
          placeholder="Enter your Password"
          fullWidth
          sx={{ mt: 2 }}
        />
        <br />
        <Button variant="contained" sx={{ mt: 2, backgroundColor: "#00321F" }}>
          Login
        </Button>
      </Box>
      <Footer/>
    </>
  );
};

export default Profile;
