import axios from "axios";
import { createContext, useEffect, useReducer } from "react";
import reducer from "../reducer/productReducer";

export const AppContext = createContext('');

const getLocalCartData = () => {
  let localCartData = localStorage.getItem("localCart");
  if (!localCartData) {
    return [];
  } else {
    try {
      return JSON.parse(localCartData);
    } catch (error) {
      console.error("Error parsing local cart data:", error);
      return [];
    }
  }
}

const initialState = {
  isLoading: false,
  isError: false,
  products: [],
  featureProducts: [],
  cartData: getLocalCartData(),
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getProducts = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const res = await axios.get(url);
      const products = await res.data;
      dispatch({ type: "SET_API_DATA", payload: products });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  useEffect(() => {
    getProducts(process.env.REACT_APP_API_URL);
  }, []);

  useEffect(() => {
    localStorage.setItem("localCart", JSON.stringify(state.cartData));
  }, [state.cartData]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// custom hooks

export default AppProvider;
